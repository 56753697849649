var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"visible":_vm.visible,"title":"编辑","close-on-click-modal":false,"close-on-press-escape":false,"fullscreen":true},on:{"update:visible":function($event){_vm.visible=$event}}},[_c('vxe-table',{ref:"xTable",staticClass:"sortable-row-gen",attrs:{"border":"","row-key":"","size":"mini","data":_vm.tableData,"checkbox-config":{checkStrictly: true},"edit-config":{trigger: 'click', mode: 'cell', icon: 'fa fa-pencil'}}},[_c('vxe-table-column',{attrs:{"type":"seq","width":"35"}}),_c('vxe-table-column',{attrs:{"width":"34","title":"拖动"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('span',{staticClass:"drag-btn"},[_c('i',{staticClass:"vxe-icon--menu"})])]},proxy:true},{key:"header",fn:function(){return [_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"按住后可以上下拖动排序","placement":"top-start"}},[_c('i',{staticClass:"vxe-icon--question"})])]},proxy:true}])}),_c('vxe-table-column',{attrs:{"title":"字段","align":"center"}},[_c('vxe-table-column',{attrs:{"field":"columnName","width":"140","title":"字段名"}}),_c('vxe-table-column',{attrs:{"field":"attrName","width":"140","title":"属性名","edit-render":{name: 'input', type: 'visible'}}}),_c('vxe-table-column',{attrs:{"field":"columnType","width":"140","title":"字段类型"}}),_c('vxe-table-column',{attrs:{"field":"attrType","width":"140","title":"属性类型","edit-render":{name: 'select', type: 'visible', options: _vm.typeList }}}),_c('vxe-table-column',{attrs:{"field":"columnComment","width":"140","title":"字段说明","edit-render":{name: 'input', type: 'visible'}}})],1),_c('vxe-table-column',{attrs:{"title":"列表","align":"center"}},[_c('vxe-table-column',{attrs:{"field":"list","width":"75","title":"列表"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('vxe-checkbox',{model:{value:(row.list),callback:function ($$v) {_vm.$set(row, "list", $$v)},expression:"row.list"}})]}}])}),_c('vxe-table-column',{attrs:{"field":"query","width":"75","title":"查询"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('vxe-checkbox',{model:{value:(row.query),callback:function ($$v) {_vm.$set(row, "query", $$v)},expression:"row.query"}})]}}])}),_c('vxe-table-column',{attrs:{"field":"queryType","width":"140","title":"查询方式","edit-render":{name: 'select', type: 'visible', options: _vm.queryList }}})],1),_c('vxe-table-column',{attrs:{"title":"表单","align":"center"}},[_c('vxe-table-column',{attrs:{"field":"form","width":"70","title":"表单"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('vxe-checkbox',{model:{value:(row.form),callback:function ($$v) {_vm.$set(row, "form", $$v)},expression:"row.form"}})]}}])}),_c('vxe-table-column',{attrs:{"field":"required","width":"70","title":"必填"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('vxe-checkbox',{model:{value:(row.required),callback:function ($$v) {_vm.$set(row, "required", $$v)},expression:"row.required"}})]}}])}),_c('vxe-table-column',{attrs:{"field":"formType","width":"140","title":"表单类型","edit-render":{name: 'select', type: 'visible', options: _vm.formTypeList }}}),_c('vxe-table-column',{attrs:{"field":"dictName","width":"140","title":"字典名称","edit-render":{name: 'select', type: 'visible', options: _vm.dictList }}})],1)],1),_c('template',{slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.visible = false}}},[_vm._v("取消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.updateHandle()}}},[_vm._v("保存")])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }